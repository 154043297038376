<script setup>
    import { ref } from 'vue'

    const entry = await useStatamic().getEntry('pages', 'home')
    const container = ref(null)
</script>

<template>
    <div class="">
        <div class="section-banner">
            <img :src="entry.banner_image?.permalink" />

            <div class="section-banner__content container relative z-20 mx-auto text-center text-white">
                <h1 class="text-4xl">{{ entry.banner_title }}</h1>
                <p class="mt-2 leading-tight">{{ entry.banner_content }}</p>
            </div>

            <div v-if="entry.banner_foreground_image" class="banner-foreground-image">
                <img :src="entry.banner_foreground_image?.permalink" />
            </div>
        </div>

        <div ref="container" name="content">
            <slot />

            <div class="container mt-10">
                <FaqListing />
            </div>
        </div>

        <div class="py-md mt-md bg-light">
            <ClientOnly>
                <component
                    is="script"
                    type="text/javascript"
                    src="https://reputationhub.site/reputation/assets/review-widget.js"
                ></component>
                <iframe
                    class="lc_reviews_widget"
                    src="https://reputationhub.site/reputation/widgets/review_widget/OpgVNfizBtgri82xaExv"
                    frameborder="0"
                    scrolling="no"
                    style="min-width: 100%; width: 100%"
                ></iframe>
            </ClientOnly>
        </div>
    </div>
</template>

<style scoped>
    .container {
        scroll-margin: calc(var(--header-height) + 1rem);
    }

    .section-banner {
        --bottom-height: clamp(1rem, -0.231rem + 6.838vw, 5rem);

        position: relative;
        max-height: 1200px;
        min-height: 300px;

        background-color: #5d635a;

        &__content {
            position: relative;
            z-index: 20;
        }

        button {
            bottom: var(--bottom-height);
            left: 50%;
            transform: translate(-50%, 50%);
        }

        .banner-foreground-image {
            @apply absolute z-10 w-[45vw];
            left: var(--padding);
            top: 50%;
            transform: translateY(-50%);
        }

        > img {
            position: absolute;
            inset: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        @screen sm {
            > img {
                position: relative;
                object-fit: contain;
                width: 100%;
                inset: auto;
            }
        }

        @screen xl {
            min-height: 0;
            height: calc(100vh - var(--header-height));
            padding-top: 12vh;

            > img {
                position: absolute;
                inset: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .banner-foreground-image {
                @apply absolute z-10 w-[31vw];
                left: 10vw;
            }
        }
    }
</style>
